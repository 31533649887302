<template>
    <div id="notifications">

        <b-modal hide-footer size="sm" ref="ntf-modal" title="Send Notification To Mobile Users">
            <b-row>

                <b-col cols="12">
                    <b-form-group label="Topic">
                        <b-form-select v-model="ntf.topic" :options="topics" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Type">
                        <b-form-select v-model="ntf.type" :options="types" />
                    </b-form-group>
                </b-col>
                <b-col cols="12" v-show="ntf.type == 'NewProduct'">
                    <b-form-group label="Item Type">
                        <b-form-select v-model="ntf.cat" :options="cats" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Title">
                        <b-form-input v-model="ntf.title" placeholder="Title" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Body">
                        <b-form-textarea v-model="ntf.body" rows="2" placeholder="Body" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Image URL">
                        <b-form-input v-model="ntf.image" placeholder="Image URL" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Link">
                        <b-form-input v-model="ntf.link" placeholder="Link" />
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Link Param">
                        <b-form-input v-model="ntf.linkParam" placeholder="Link Param" />
                    </b-form-group>
                </b-col>
            </b-row>

            <hr />

            <b-row class="text-right">

                <b-col cols="12" md="12">
                    <b-button :disabled="!sendNotificationBtn" @click="sendNotification" variant="relief-success">
                        Send Notification
                    </b-button>
                </b-col>

            </b-row>

        </b-modal>


        <b-overlay :show="showLoading" spinner-variant="primary">
            <b-card>
                <ag-grid :withFilters="true" :filterComponent="AgGridDateFilter" @filterClicked="filterClicked"
                    :agGrid="agGrid" pageTitle="Notifications History" :add="true" @plusClicked="plusClicked"
                    @refresh="loadData" />
            </b-card>
        </b-overlay>

    </div>
</template>

<script>

import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import AgGridDateFilter from '@/views/components/ag-grid/AgGridDateFilter'

export default {
    components: {
        AgGrid,
    },
    data() {
        return {
            ntf: {

            },
            topics: ["allUsers"],
            types: ["Announcement", "NewProduct"],
            cats: ["MED", "PARA"],
            AgGridDateFilter: AgGridDateFilter,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Created At",
                        field: "createdAt",
                    },
                    {
                        headerName: "Created By",
                        field: "createdBy",
                    },
                    {
                        headerName: "Topic",
                        field: "topic",
                    },
                    {
                        headerName: "Type",
                        field: "type",
                    },
                    {
                        headerName: "Title",
                        field: "title",
                    },
                    {
                        headerName: "Body",
                        field: "body",
                    },
                    {
                        headerName: "Image",
                        field: "image",
                    },
                    {
                        headerName: "Link",
                        field: "link",
                    },
                    {
                        headerName: "Link Prama",
                        field: "linkParam",
                    },
                    {
                        headerName: "Response Code",
                        field: "responseCode",
                    },
                    {
                        headerName: "Error",
                        field: "errorResponse",
                    },
                ],
                rows: []
            },
            showLoading: true,
            dateDebut: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T')[0],
            dateFin: new Date().toISOString().split('T')[0],
        }
    },
    watch: {
        "ntf.type"(value) {
            if (value == "Announcement") {
                this.ntf.title = "🔔Nouveau Circulaire PCT Disponible ";
                this.ntf.body = "📋Découvrez les dernières mises à jour et informations importantes dans le nouveau circulaire de la PCT. 🌟 Ne manquez pas cette lecture essentielle.";
                this.ntf.link = "";
            } else {
                this.ntf.title = "";
                this.ntf.body = "";
            }
        },
        "ntf.cat"(value) {
            if (value == "MED") {
                this.ntf.title = "🔔Nouvel Article Disponible ⚕️";
                this.ntf.body = "Plongez dans notre dernier article pour rester à la pointe de l'actualité pharmaceutique.";
                this.ntf.link = "MEDDetails";
            } else {
                this.ntf.title = "🔔Nouveauté sur PharmaConnect 🆕";
                this.ntf.body = "Un nouvel article est en ligne. Explorez les dernières tendances sur PharmaConnect.";
                this.ntf.link = "PARADetails";
            }
        }
    },
    computed: {
        sendNotificationBtn() {
            return this.ntf.topic != undefined && this.ntf.topic != "" &&
                this.ntf.type != undefined && this.ntf.type != "" &&
                this.ntf.title != undefined && this.ntf.title != "" &&
                this.ntf.body != undefined && this.ntf.body != ""
        }
    },
    created() {
        this.loadData();
    },
    methods: {
        async sendNotification() {
            this.$refs['ntf-modal'].hide();
            this.showLoading = true;
            this.ntf.type = this.ntf.cat;
            await this.$http.post("notification", this.ntf);
            this.loadData();
        },
        plusClicked() {
            this.ntf = {};
            setTimeout(() => {
                this.$refs['ntf-modal'].show();
            }, 500);
        },
        filterClicked(obj) {
            this.dateDebut = obj.sDate;
            this.dateFin = obj.eDate;
            this.loadData();
        },
        async loadData() {
            this.showLoading = true;
            let response = await this.$http.get("notification" + "?from= " + this.dateDebut + "&to=" + this.dateFin);
            this.agGrid.rows = response.data;
            this.showLoading = false;
        },
    }
}
</script>

<style lang="scss"></style>
